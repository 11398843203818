import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (address) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let isPresale = await store
        .getState()
        .blockchain.smartContract.methods.isPresale()
        .call();
      let isStartMinting = await store
        .getState()
        .blockchain.smartContract.methods.isStartMinting()
        .call();
      let userBalance = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(address)
        .call();

      let userMintBalance = await store
        .getState()
        .blockchain.smartContract.methods.userMintBalance(address)
        .call();

      let userMintSkulistBalance = await store
        .getState()
        .blockchain.smartContract.methods.userMintSkulistBalance(address)
        .call();

      let userMintOGBalance = await store
        .getState()
        .blockchain.smartContract.methods.userMintOGBalance(address)
        .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          isPresale,
          isStartMinting,
          userBalance: +userBalance || 0,
          userMintBalance: +userMintBalance || 0,
          userMintSkulistBalance: +userMintSkulistBalance || 0,
          userMintOGBalance: +userMintOGBalance || 0,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
