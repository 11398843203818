import axios from "axios";

const fetchWhitelistRequest = () => {
  return {
    type: "CHECK_WHITELIST_REQUEST",
  };
};

const fetchWhitelistSuccess = (payload) => {
  return {
    type: "CHECK_WHITELIST_SUCCESS",
    payload: payload,
  };
};

const fetchWhitelistFailed = (payload) => {
  return {
    type: "CHECK_WHITELIST_FAILED",
    payload: payload,
  };
};

export const fetchWhitelist = (api, connectedAccount) => {
  return async (dispatch) => {
    dispatch(fetchWhitelistRequest());
    try {
      if (connectedAccount == null) return;
      let data = await axios.get(
        `${api}/api/user/` + connectedAccount.toLowerCase()
      );
      let signature = data?.data?.signature;
      let isVIP = data?.data?.isVIP;

      // console.log({ signature, isVIP });
      if (signature) {
        dispatch(
          fetchWhitelistSuccess({
            signature,
            isVIP,
          })
        );
      } else {
        dispatch(
          fetchWhitelistSuccess({
            signature: "0x00",
            isVIP: false,
          })
        );
      }
    } catch (err) {
      dispatch(fetchWhitelistFailed("Could not load data from whitelist api."));
    }
  };
};
