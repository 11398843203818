import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchWhitelist } from "./redux/whitelist/whitelistActions";
import * as s from "./styles/globalStyles";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const whitelist = useSelector((state) => state.whitelist);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintStatus, setMintStatus] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    API: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    MAX_ITEM_PER_MINT: 2,
    MAX_ITEM_PER_MINT_NON_VIP: 2,
  });

  const [image, setImage] = useState("assets/images/banner/asset1.png");

  useEffect(() => {
    // console.log({ whitelist, data });
    if (!data.isPresale) {
      return setImage("assets/images/banner/Mint-Asset-Public&Mint.png");
    }

    if (whitelist.signature != "0x00") {
      if (whitelist.isVIP) {
        return setImage("assets/images/banner/Mint-Asset-OG&Mint.png");
      } else {
        return setImage("assets/images/banner/Mint-Asset-Skullist&Mint.png");
      }
    } else {
      return setImage("assets/images/banner/Mint-Asset-Public&Mint.png");
    }
  }, [whitelist, data]);

  const mintNormal = async () => {
    let cost = CONFIG.WEI_COST;
    // let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        // gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        setMintStatus(-1);
        setFeedback(`Something went wrong.`);
        setClaimingNft(false);
      })
      .then((receipt) => {
        setMintStatus(1);
        setFeedback(
          `You've minted ${CONFIG.NFT_NAME}. Please visit Opensea to view!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
        $("#NFTModal").modal("toggle");
      });
  };

  const mintPrivate = async () => {
    let cost = CONFIG.WEI_COST;
    // let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .mintPrivate(mintAmount, whitelist.signature, whitelist.isVIP)
      .send({
        // gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        setMintStatus(-1);
        setFeedback(`Something went wrong.`);
        setClaimingNft(false);
      })
      .then((receipt) => {
        setMintStatus(1);
        setFeedback(
          `You've minted ${CONFIG.NFT_NAME}. Please visit Opensea to view!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
        $("#NFTModal").modal("toggle");
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) newMintAmount = 1;
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    let mintChecker = 0;

    if (data.isPresale && whitelist.isVIP) {
      mintChecker = CONFIG.MAX_ITEM_PER_MINT - data.userMintOGBalance;
    } else if (data.isPresale && !whitelist.isVIP) {
      mintChecker = CONFIG.MAX_ITEM_PER_MINT - data.userMintSkulistBalance;
    } else if (!data.isPresale) {
      mintChecker = CONFIG.MAX_ITEM_PER_MINT - data.userMintBalance;
    }
    if (newMintAmount > mintChecker) newMintAmount = mintChecker;
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      dispatch(fetchWhitelist(CONFIG.API, blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  // UI
  const panelNYConnect = () => {
    return (
      <>
        <div
          className="row gy-5 align-items-center"
          style={{ marginTop: "3px" }}
        >
          <div className="col-lg-2 col-12"></div>
          <div className="col-lg-8 col-12">
            <div className="banner-content">
              <div
                style={{
                  maxWidth: "100%",
                  textAlign: "center",
                  backgroundColor: "black",
                  paddingTop: "35px",
                  fontFamily: "'colvetica', sans-serif",
                  fontSize: "25px",
                  backgroundImage: "url(assets/images/banner/bg-image1.png)",
                  backgroundSize: "cover",
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: "4px 6px 5px 7px",
                  borderRadius: "95% 4% 92% 5%/4% 95% 6% 95%",
                  transform: "rotate(2deg)",
                }}
              >
                <img
                  src="assets/images/banner/prereveal.png"
                  alt="banner-img"
                  className="wow fadeInUpBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                  style={{ width: "50%" }}
                />
                <h1
                  className="wow fadeInUpBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.75s"
                  style={{
                    fontSize: "32px",
                    marginTop: "10px",
                    color: "black",
                    textShadow: "none",
                  }}
                >
                  MINTING IS LIVE!
                </h1>
                <a
                  className="lab-btn wow fadeInUpBig"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                  style={{ marginBottom: "35px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  <span style={{ fontFamily: "colvetica" }}>
                    Connect Wallet
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const NFTModal = () => {
    return (
      <>
        <div
          className="modal fade"
          id="NFTModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="NFTModalCenterTitle"
          aria-hidden="true"
          style={{ zIndex: 1050 }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
            role="document"
            style={{ width: "45%" }}
          >
            <div
              className="modal-content bg-dark"
              style={{
                borderColor: "rgb(188,221,239)",
                borderStyle: "solid",
                borderRadius: "3%",
                borderWidth: 4,
              }}
            >
              <div className="modal-body">
                <div
                  className="container mb-4"
                  style={{ justifyContent: "center" }}
                >
                  <h3
                    style={{
                      textAlign: "center",
                      fontSize: 33,
                    }}
                  >
                    CONGRATULATIONS
                  </h3>
                  <h4
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontFamily: "colvetica",
                    }}
                  >
                    You've successfully minted Skulourful NFT.
                  </h4>
                  <h4
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontFamily: "colvetica",
                    }}
                  >
                    Visit the collection on{" "}
                    <a
                      style={{
                        color: "rgb(188,221,239)",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      href={CONFIG.MARKETPLACE_LINK}
                      target="_blank"
                    >
                      {CONFIG.MARKETPLACE}
                    </a>
                  </h4>
                  <div style={{ justifyContent: "center", display: "flex" }}>
                    <img
                      src="assets/images/logo/Success-Modal.png"
                      alt="banner-img"
                      className="wow fadeInUpBig"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                      style={{ width: "30%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const panelNotLive = () => {
    return (
      <>
        <div className="row gy-5 align-items-center">
          <div className="col-lg-2 col-12"></div>
          <div className="col-lg-8 col-12">
            <div className="banner-content">
              <div
                style={{
                  maxWidth: "100%",
                  textAlign: "center",
                  backgroundColor: "black",
                  padding: "25px",
                  fontFamily: "'colvetica', sans-serif",
                  fontSize: "25px",
                  backgroundImage: "url(assets/images/banner/bg-image1.png)",
                  backgroundSize: "cover",
                  borderStyle: "solid",
                  borderColor: "black",
                  color: "black",
                  borderWidth: "4px 6px 5px 7px",
                  borderRadius: "95% 4% 92% 5%/4% 95% 6% 95%",
                  transform: "rotate(2deg)",
                }}
              >
                <img
                  src="assets/images/banner/asset1.png"
                  alt="banner-img"
                  className="wow fadeInUpBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                  style={{ width: "70%" }}
                />
                <h1
                  className="wow fadeInUpBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.75s"
                  style={{ fontSize: "40px", marginTop: "10px" }}
                >
                  MINTING SOON!
                </h1>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const panelError = () => {
    return (
      <>
        <div className="row gy-5 align-items-center">
          <div className="col-lg-2 col-12"></div>
          <div className="col-lg-8 col-12">
            <div className="banner-content">
              <div
                style={{
                  maxWidth: "100%",
                  textAlign: "center",
                  backgroundColor: "black",
                  padding: "25px",
                  fontFamily: "'colvetica', sans-serif",
                  fontSize: "25px",
                  backgroundImage: "url(assets/images/banner/bg-image1.png)",
                  backgroundSize: "cover",
                  borderStyle: "solid",
                  borderColor: "black",
                  color: "black",
                  borderWidth: "4px 6px 5px 7px",
                  borderRadius: "95% 4% 92% 5%/4% 95% 6% 95%",
                  transform: "rotate(2deg)",
                }}
              >
                <img
                  src="assets/images/banner/asseterror.png"
                  alt="banner-img"
                  className="wow fadeInUpBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                  style={{ width: "50%" }}
                />
                <h1
                  className="wow fadeInUpBig"
                  data-wow-duration="1s"
                  data-wow-delay="0.75s"
                  style={{ fontSize: "38px", marginTop: "20px" }}
                >
                  {blockchain.errorMsg == "Install Wallet." ? (
                    <>
                      {" "}
                      <span style={{ color: "#d30000" }}>Wallet</span> Not
                      Installed{" "}
                    </>
                  ) : (
                    <>
                      {blockchain.errorMsg == "Change network to Ethereum." ? (
                        <> You are on the wrong network </>
                      ) : (
                        <> Something went wrong </>
                      )}
                    </>
                  )}
                </h1>
                <p
                  className="wow fadeInUpBig"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                  style={{ fontSize: "20px", paddingBottom: "35px" }}
                >
                  {blockchain.errorMsg == "Install Wallet." ? (
                    <>
                      {" "}
                      Please Install{" "}
                      <a href="https://youtu.be/yWfZnjkhhhg">
                        <b>Wallet</b>
                      </a>
                      .{" "}
                    </>
                  ) : (
                    <>
                      {blockchain.errorMsg == "Change network to Ethereum." ? (
                        <>
                          {" "}
                          Please connect to{" "}
                          <span style={{ color: "#d30000" }}>
                            <b>Ethereum Mainnet</b>
                          </span>
                          .
                        </>
                      ) : (
                        <> {blockchain.errorMsg} </>
                      )}
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const panelMint = () => {
    return (
      <>
        {data.isPresale && whitelist.signature !== "0x00" && (
          <div
            className="row gy-5 align-items-center"
            style={{ marginTop: "5px" }}
          >
            <div className="col-lg-12 col-12">
              <div className="banner-content">
                <div
                  className="row"
                  style={{
                    maxWidth: "100%",
                    textAlign: "center",
                    backgroundColor: "black",
                    padding: "25px",
                    fontFamily: "'colvetica', sans-serif",
                    fontSize: "25px",
                    backgroundImage: "url(assets/images/banner/bg-image1.png)",
                    backgroundSize: "cover",
                    borderStyle: "solid",
                    borderColor: "black",
                    color: "black",
                    borderWidth: "4px 6px 5px 7px",
                    borderRadius: "95% 4% 92% 5%/4% 95% 6% 95%",
                    transform: "rotate(2deg)",
                  }}
                >
                  <div className="col-lg-6 col-12">
                    <img
                      src={image}
                      alt="banner-img"
                      className="wow fadeInUpBig"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    />
                  </div>
                  <div className="col-lg-6 col-12" style={{ display: "table" }}>
                    <div
                      style={{ display: "table-cell", verticalAlign: "middle" }}
                    >
                      <h1
                        className="wow fadeInUpBig"
                        data-wow-duration="1s"
                        data-wow-delay="0.5s"
                        style={{
                          fontSize: "44px",
                          fontFamily: "colvetica",
                          color: "#F09CB2",
                          textShadow: "none",
                          WebkitTextStrokeColor: "black",
                          WebkitTextStrokeWidth: "1.4px",
                        }}
                      >
                        Mint Your Skullz
                      </h1>

                      <div style={{ marginTop: "40px" }}>
                        <b
                          className="wow fadeInUpBig"
                          data-wow-duration="1s"
                          data-wow-delay="0.75s"
                          style={{
                            fontSize: "35px",
                            fontFamily: "colvetica",
                            backgroundColor: "transparent",
                            color: "#96E2CE",
                            textShadow: "none",
                            WebkitTextStrokeColor: "black",
                            WebkitTextStrokeWidth: "1px",
                          }}
                        >
                          <span
                            style={{
                              color: "#B8A4DD",
                              fontFamily: "colvetica",
                              backgroundColor: "transparent",
                            }}
                          >
                            {data.totalSupply}
                          </span>{" "}
                          &nbsp; / &nbsp; {CONFIG.MAX_SUPPLY}
                        </b>
                        <br />
                        <br />

                        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                          <>
                            <span
                              className="wow fadeInUpBig"
                              data-wow-duration="1s"
                              data-wow-delay="1s"
                              style={{ fontSize: "25px" }}
                            >
                              <b>The Sale has Ended</b>
                            </span>
                            <br />
                            <span
                              className="wow fadeInUpBig"
                              data-wow-duration="1s"
                              data-wow-delay="1.1s"
                              style={{ fontSize: "20px" }}
                            >
                              You can still find {CONFIG.NFT_NAME} on{" "}
                              <a
                                style={{
                                  textDecoration: "underline",
                                  color: "#d30000",
                                }}
                                href={CONFIG.MARKETPLACE_LINK}
                              >
                                {CONFIG.MARKETPLACE}
                              </a>
                            </span>
                          </>
                        ) : (
                          <>
                            <div
                              className="row wow fadeInUpBig"
                              data-wow-duration="1s"
                              data-wow-delay="1s"
                            >
                              <div
                                className="col-lg-3 col-12"
                                style={{ verticalAlign: "middle" }}
                              >
                                <button
                                  disabled={claimingNft ? 1 : 0}
                                  style={{
                                    width: "36px",
                                    height: "36px",
                                    border: "none",
                                    backgroundImage:
                                      "url('assets/images/shape/min.png')",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "100%",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    decrementMintAmount();
                                  }}
                                ></button>
                              </div>
                              <div
                                className="col-lg-6 col-12"
                                style={{
                                  fontSize: "36px",
                                  marginTop: "-8px",
                                  border: "1px solid #2D6279",
                                }}
                              >
                                {mintAmount}
                              </div>
                              <div className="col-lg-3 col-12">
                                <button
                                  disabled={claimingNft ? 1 : 0}
                                  style={{
                                    width: "36px",
                                    height: "36px",
                                    border: "none",
                                    backgroundImage:
                                      "url('assets/images/shape/plus.png')",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "100%",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    incrementMintAmount();
                                  }}
                                ></button>
                              </div>
                            </div>
                            <div
                              className="row wow fadeInUpBig"
                              data-wow-duration="1s"
                              data-wow-delay="1.1s"
                              style={{ fontSize: "30px", marginTop: "10px" }}
                            >
                              <div className="col-lg-12 col-12">
                                <span style={{ fontSize: "20px" }}>
                                  {" "}
                                  Price{" "}
                                </span>
                              </div>
                              <div
                                className="col-lg-6 col-12"
                                style={{
                                  border: "1px solid #2D6279",
                                }}
                              >
                                <span>
                                  {mintAmount} x {CONFIG.DISPLAY_COST} ETH{" "}
                                </span>
                              </div>
                              <div
                                className="col-lg-6 col-12"
                                style={{
                                  border: "1px solid #2D6279",
                                }}
                              >
                                <span>
                                  {" "}
                                  {Number.parseFloat(
                                    mintAmount * CONFIG.DISPLAY_COST
                                  ).toFixed(3)}{" "}
                                  ETH{" "}
                                </span>
                              </div>
                            </div>

                            {data.isPresale && (
                              <>
                                {whitelist.signature != "0x00" ? (
                                  <>
                                    {whitelist.isVIP ? (
                                      <div
                                        className="wow fadeInUpBig"
                                        style={{
                                          flex: 1,
                                          display: "flex",
                                          flexDirection: "column",
                                          color: "#2D6279",
                                        }}
                                        data-wow-duration="1s"
                                        data-wow-delay="1.2s"
                                      >
                                        <span>1 Free NFT for first tx</span>
                                        <span>
                                          Remaining mint:{" "}
                                          {CONFIG.MAX_ITEM_PER_MINT -
                                            data.userMintOGBalance}
                                        </span>
                                      </div>
                                    ) : (
                                      <span
                                        className="wow fadeInUpBig"
                                        style={{ color: "#2D6279" }}
                                        data-wow-duration="1s"
                                        data-wow-delay="1.2s"
                                      >
                                        Remaining mint:{" "}
                                        {CONFIG.MAX_ITEM_PER_MINT -
                                          data.userMintSkulistBalance}
                                      </span>
                                    )}
                                    {whitelist.isVIP &&
                                      (data.userMintOGBalance <
                                      CONFIG.MAX_ITEM_PER_MINT ? (
                                        <button
                                          className="lab-btn wow fadeInUpBig"
                                          data-wow-duration="1s"
                                          data-wow-delay="1.2s"
                                          disabled={claimingNft ? 1 : 0}
                                          style={{
                                            width: "100%",
                                            margin: "20px 0px 0px 0px",
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            mintPrivate();
                                            getData();
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "40px",
                                              fontFamily: "skull",
                                            }}
                                          >
                                            {claimingNft
                                              ? "On Process"
                                              : `MINT`}
                                          </span>
                                        </button>
                                      ) : (
                                        <button
                                          className="lab-btn wow fadeInUpBig"
                                          data-wow-duration="1s"
                                          data-wow-delay="1.2s"
                                          disabled
                                          style={{
                                            width: "100%",
                                            margin: "20px 0px 0px 0px",
                                            backgroundColor: "gray",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "40px",
                                              fontFamily: "skull",
                                            }}
                                          >
                                            {claimingNft
                                              ? "On Process"
                                              : `MINT`}
                                          </span>
                                        </button>
                                      ))}
                                    {!whitelist.isVIP &&
                                      (data.userMintSkulistBalance <
                                      CONFIG.MAX_ITEM_PER_MINT ? (
                                        <button
                                          className="lab-btn wow fadeInUpBig"
                                          data-wow-duration="1s"
                                          data-wow-delay="1.2s"
                                          disabled={claimingNft ? 1 : 0}
                                          style={{
                                            width: "100%",
                                            margin: "20px 0px 0px 0px",
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            mintPrivate();
                                            getData();
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "40px",
                                              fontFamily: "skull",
                                            }}
                                          >
                                            {claimingNft
                                              ? "On Process"
                                              : `MINT`}
                                          </span>
                                        </button>
                                      ) : (
                                        <button
                                          className="lab-btn wow fadeInUpBig"
                                          data-wow-duration="1s"
                                          data-wow-delay="1.2s"
                                          disabled
                                          style={{
                                            width: "100%",
                                            margin: "20px 0px 0px 0px",
                                            backgroundColor: "gray",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "40px",
                                              fontFamily: "skull",
                                            }}
                                          >
                                            {claimingNft
                                              ? "On Process"
                                              : `MINT`}
                                          </span>
                                        </button>
                                      ))}
                                  </>
                                ) : (
                                  <button
                                    className="lab-btn wow fadeInUpBig"
                                    data-wow-duration="1s"
                                    data-wow-delay="1.2s"
                                    disabled={true}
                                    style={{
                                      width: "100%",
                                      margin: "20px 0px 0px 0px",
                                    }}
                                  >
                                    <span
                                      sstyle={{
                                        fontSize: "40px",
                                        fontFamily: "skull",
                                      }}
                                    >
                                      {"NOT WHITELISTED"}
                                    </span>
                                  </button>
                                )}
                              </>
                            )}
                            {!data.isPresale && (
                              <>
                                <span
                                  className="wow fadeInUpBig"
                                  data-wow-duration="1s"
                                  data-wow-delay="1.5s"
                                  style={{ color: "#2D6279" }}
                                >
                                  Remaining mint:{" "}
                                  {CONFIG.MAX_ITEM_PER_MINT -
                                    data.userMintBalance}
                                </span>
                                {data.userMintBalance <
                                CONFIG.MAX_ITEM_PER_MINT ? (
                                  <button
                                    className="lab-btn wow fadeInUpBig"
                                    data-wow-duration="1s"
                                    data-wow-delay="1.2s"
                                    disabled={claimingNft ? 1 : 0}
                                    style={{
                                      width: "100%",
                                      margin: "20px 0px 0px 0px",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      mintNormal();
                                      getData();
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "40px",
                                        fontFamily: "skull",
                                      }}
                                    >
                                      {claimingNft ? "On Process" : "MINT"}
                                    </span>
                                  </button>
                                ) : (
                                  <button
                                    className="lab-btn wow fadeInUpBig"
                                    data-wow-duration="1s"
                                    data-wow-delay="1.2s"
                                    disabled
                                    style={{
                                      width: "100%",
                                      margin: "20px 0px 0px 0px",
                                      backgroundColor: "gray",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "40px",
                                        fontFamily: "skull",
                                      }}
                                    >
                                      {claimingNft ? "On Process" : "MINT"}
                                    </span>
                                  </button>
                                )}
                              </>
                            )}
                            <div
                              style={{ fontSize: "18px", marginTop: "10px" }}
                            >
                              {mintStatus > 0 ? (
                                <>
                                  {" "}
                                  <span style={{ color: "#fff" }}>
                                    {feedback}
                                  </span>{" "}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <span style={{ color: "#fff" }}>
                                    {feedback}
                                  </span>{" "}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {data.isPresale && whitelist.signature == "0x00" && (
          <>
            <div className="row gy-5 align-items-center">
              <div className="col-lg-2 col-12"></div>
              <div className="col-lg-8 col-12">
                <div className="banner-content">
                  <div
                    style={{
                      maxWidth: "100%",
                      textAlign: "center",
                      backgroundColor: "black",
                      padding: "25px",
                      fontFamily: "'colvetica', sans-serif",
                      fontSize: "25px",
                      backgroundImage:
                        "url(assets/images/banner/bg-image1.png)",
                      backgroundSize: "cover",
                      borderStyle: "solid",
                      borderColor: "black",
                      color: "black",
                      borderWidth: "4px 6px 5px 7px",
                      borderRadius: "95% 4% 92% 5%/4% 95% 6% 95%",
                      transform: "rotate(2deg)",
                    }}
                  >
                    <img
                      src="assets/images/banner/asseterror.png"
                      alt="banner-img"
                      className="wow fadeInUpBig"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                      style={{ width: "50%" }}
                    />
                    <h1
                      className="wow fadeInUpBig"
                      data-wow-duration="1s"
                      data-wow-delay="0.75s"
                      style={{ fontSize: "38px", marginTop: "20px" }}
                    >
                      RESTRICTED ACCESS
                    </h1>
                    <p
                      className="wow fadeInUpBig"
                      data-wow-duration="1s"
                      data-wow-delay="1s"
                      style={{
                        fontSize: "20px",
                        fontFamily: "colvetica",
                        marginBottom: 0,
                        paddingBottom: 0,
                      }}
                    >
                      Sorry, you're not a Skuluminati and not included in the
                      Skullist.
                    </p>
                    <p
                      className="wow fadeInUpBig"
                      data-wow-duration="1s"
                      data-wow-delay="1s"
                      style={{
                        fontSize: "20px",
                        paddingBottom: "35px",
                        fontFamily: "colvetica",
                      }}
                    >
                      Try again during Public Sale.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {!data.isPresale && (
          <div
            className="row gy-5 align-items-center"
            style={{ marginTop: "5px" }}
          >
            <div className="col-lg-12 col-12">
              <div className="banner-content">
                <div
                  className="row"
                  style={{
                    maxWidth: "100%",
                    textAlign: "center",
                    backgroundColor: "black",
                    padding: "25px",
                    fontFamily: "'colvetica', sans-serif",
                    fontSize: "25px",
                    backgroundImage: "url(assets/images/banner/bg-image1.png)",
                    backgroundSize: "cover",
                    borderStyle: "solid",
                    borderColor: "black",
                    color: "black",
                    borderWidth: "4px 6px 5px 7px",
                    borderRadius: "95% 4% 92% 5%/4% 95% 6% 95%",
                    transform: "rotate(2deg)",
                  }}
                >
                  <div className="col-lg-6 col-12">
                    <img
                      src={image}
                      alt="banner-img"
                      className="wow fadeInUpBig"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    />
                  </div>
                  <div className="col-lg-6 col-12" style={{ display: "table" }}>
                    <div
                      style={{ display: "table-cell", verticalAlign: "middle" }}
                    >
                      <h1
                        className="wow fadeInUpBig"
                        data-wow-duration="1s"
                        data-wow-delay="0.5s"
                        style={{
                          fontSize: "44px",
                          fontFamily: "colvetica",
                          color: "#F09CB2",
                          textShadow: "none",
                          WebkitTextStrokeColor: "black",
                          WebkitTextStrokeWidth: "1.4px",
                        }}
                      >
                        Mint Your Skullz
                      </h1>

                      <div style={{ marginTop: "40px" }}>
                        <b
                          className="wow fadeInUpBig"
                          data-wow-duration="1s"
                          data-wow-delay="0.75s"
                          style={{
                            fontSize: "35px",
                            fontFamily: "colvetica",
                            backgroundColor: "transparent",
                            color: "#96E2CE",
                            textShadow: "none",
                            WebkitTextStrokeColor: "black",
                            WebkitTextStrokeWidth: "1px",
                          }}
                        >
                          <span
                            style={{
                              color: "#B8A4DD",
                              fontFamily: "colvetica",
                              backgroundColor: "transparent",
                            }}
                          >
                            {data.totalSupply}
                          </span>{" "}
                          &nbsp; / &nbsp; {CONFIG.MAX_SUPPLY}
                        </b>
                        <br />
                        <br />

                        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                          <>
                            <span
                              className="wow fadeInUpBig"
                              data-wow-duration="1s"
                              data-wow-delay="1s"
                              style={{ fontSize: "25px" }}
                            >
                              <b>The Sale has Ended</b>
                            </span>
                            <br />
                            <span
                              className="wow fadeInUpBig"
                              data-wow-duration="1s"
                              data-wow-delay="1.1s"
                              style={{ fontSize: "20px" }}
                            >
                              You can still find {CONFIG.NFT_NAME} on{" "}
                              <a
                                style={{
                                  textDecoration: "underline",
                                  color: "#d30000",
                                }}
                                href={CONFIG.MARKETPLACE_LINK}
                              >
                                {CONFIG.MARKETPLACE}
                              </a>
                            </span>
                          </>
                        ) : (
                          <>
                            <div
                              className="row wow fadeInUpBig"
                              data-wow-duration="1s"
                              data-wow-delay="1s"
                            >
                              <div
                                className="col-lg-3 col-12"
                                style={{ verticalAlign: "middle" }}
                              >
                                <button
                                  disabled={claimingNft ? 1 : 0}
                                  style={{
                                    width: "36px",
                                    height: "36px",
                                    border: "none",
                                    backgroundImage:
                                      "url('assets/images/shape/min.png')",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "100%",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    decrementMintAmount();
                                  }}
                                ></button>
                              </div>
                              <div
                                className="col-lg-6 col-12"
                                style={{
                                  fontSize: "36px",
                                  marginTop: "-8px",
                                  border: "1px solid #2D6279",
                                }}
                              >
                                {mintAmount}
                              </div>
                              <div className="col-lg-3 col-12">
                                <button
                                  disabled={claimingNft ? 1 : 0}
                                  style={{
                                    width: "36px",
                                    height: "36px",
                                    border: "none",
                                    backgroundImage:
                                      "url('assets/images/shape/plus.png')",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "100%",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    incrementMintAmount();
                                  }}
                                ></button>
                              </div>
                            </div>
                            <div
                              className="row wow fadeInUpBig"
                              data-wow-duration="1s"
                              data-wow-delay="1.1s"
                              style={{ fontSize: "30px", marginTop: "10px" }}
                            >
                              <div className="col-lg-12 col-12">
                                <span style={{ fontSize: "20px" }}>
                                  {" "}
                                  Price{" "}
                                </span>
                              </div>
                              <div
                                className="col-lg-6 col-12"
                                style={{
                                  border: "1px solid #2D6279",
                                }}
                              >
                                <span>
                                  {mintAmount} x {CONFIG.DISPLAY_COST} ETH{" "}
                                </span>
                              </div>
                              <div
                                className="col-lg-6 col-12"
                                style={{
                                  border: "1px solid #2D6279",
                                }}
                              >
                                <span>
                                  {" "}
                                  {Number.parseFloat(
                                    mintAmount * CONFIG.DISPLAY_COST
                                  ).toFixed(3)}{" "}
                                  ETH{" "}
                                </span>
                              </div>
                            </div>

                            {data.isPresale && (
                              <>
                                {whitelist.signature != "0x00" ? (
                                  <>
                                    {whitelist.isVIP ? (
                                      <div
                                        className="wow fadeInUpBig"
                                        style={{
                                          flex: 1,
                                          display: "flex",
                                          flexDirection: "column",
                                          color: "#2D6279",
                                        }}
                                        data-wow-duration="1s"
                                        data-wow-delay="1.2s"
                                      >
                                        <span>1 Free NFT for first tx</span>
                                        <span>
                                          Remaining mint:{" "}
                                          {CONFIG.MAX_ITEM_PER_MINT -
                                            data.userMintOGBalance}
                                        </span>
                                      </div>
                                    ) : (
                                      <span
                                        className="wow fadeInUpBig"
                                        style={{ color: "#2D6279" }}
                                      >
                                        Remaining mint:{" "}
                                        {CONFIG.MAX_ITEM_PER_MINT -
                                          data.userMintSkulistBalance}
                                      </span>
                                    )}
                                    {whitelist.isVIP &&
                                      (data.userMintOGBalance <
                                      CONFIG.MAX_ITEM_PER_MINT ? (
                                        <button
                                          className="lab-btn wow fadeInUpBig"
                                          data-wow-duration="1s"
                                          data-wow-delay="1.2s"
                                          disabled={claimingNft ? 1 : 0}
                                          style={{
                                            width: "100%",
                                            margin: "20px 0px 0px 0px",
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            mintPrivate();
                                            getData();
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "40px",
                                              fontFamily: "skull",
                                            }}
                                          >
                                            {claimingNft
                                              ? "On Process"
                                              : `MINT`}
                                          </span>
                                        </button>
                                      ) : (
                                        <button
                                          className="lab-btn wow fadeInUpBig"
                                          data-wow-duration="1s"
                                          data-wow-delay="1.2s"
                                          disabled
                                          style={{
                                            width: "100%",
                                            margin: "20px 0px 0px 0px",
                                            backgroundColor: "gray",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "40px",
                                              fontFamily: "skull",
                                            }}
                                          >
                                            {claimingNft
                                              ? "On Process"
                                              : `MINT`}
                                          </span>
                                        </button>
                                      ))}
                                    {!whitelist.isVIP &&
                                      (data.userMintSkulistBalance <
                                      CONFIG.MAX_ITEM_PER_MINT ? (
                                        <button
                                          className="lab-btn wow fadeInUpBig"
                                          data-wow-duration="1s"
                                          data-wow-delay="1.2s"
                                          disabled={claimingNft ? 1 : 0}
                                          style={{
                                            width: "100%",
                                            margin: "20px 0px 0px 0px",
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            mintPrivate();
                                            getData();
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "40px",
                                              fontFamily: "skull",
                                            }}
                                          >
                                            {claimingNft
                                              ? "On Process"
                                              : `MINT`}
                                          </span>
                                        </button>
                                      ) : (
                                        <button
                                          className="lab-btn wow fadeInUpBig"
                                          data-wow-duration="1s"
                                          data-wow-delay="1.2s"
                                          disabled
                                          style={{
                                            width: "100%",
                                            margin: "20px 0px 0px 0px",
                                            backgroundColor: "gray",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "40px",
                                              fontFamily: "skull",
                                            }}
                                          >
                                            {claimingNft
                                              ? "On Process"
                                              : `MINT`}
                                          </span>
                                        </button>
                                      ))}
                                  </>
                                ) : (
                                  <button
                                    className="lab-btn wow fadeInUpBig"
                                    data-wow-duration="1s"
                                    data-wow-delay="1.2s"
                                    disabled={true}
                                    style={{
                                      width: "100%",
                                      margin: "20px 0px 0px 0px",
                                    }}
                                  >
                                    <span
                                      sstyle={{
                                        fontSize: "40px",
                                        fontFamily: "skull",
                                      }}
                                    >
                                      {"NOT WHITELISTED"}
                                    </span>
                                  </button>
                                )}
                              </>
                            )}
                            {!data.isPresale && (
                              <>
                                <span
                                  className="wow fadeInUpBig"
                                  data-wow-duration="1s"
                                  data-wow-delay="1.5s"
                                  style={{ color: "#2D6279" }}
                                >
                                  Remaining mint:{" "}
                                  {CONFIG.MAX_ITEM_PER_MINT -
                                    data.userMintBalance}
                                </span>
                                {data.userMintBalance <
                                CONFIG.MAX_ITEM_PER_MINT ? (
                                  <button
                                    className="lab-btn wow fadeInUpBig"
                                    data-wow-duration="1s"
                                    data-wow-delay="1.2s"
                                    disabled={claimingNft ? 1 : 0}
                                    style={{
                                      width: "100%",
                                      margin: "20px 0px 0px 0px",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      mintNormal();
                                      getData();
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "40px",
                                        fontFamily: "skull",
                                      }}
                                    >
                                      {claimingNft ? "On Process" : "MINT"}
                                    </span>
                                  </button>
                                ) : (
                                  <button
                                    className="lab-btn wow fadeInUpBig"
                                    data-wow-duration="1s"
                                    data-wow-delay="1.2s"
                                    disabled
                                    style={{
                                      width: "100%",
                                      margin: "20px 0px 0px 0px",
                                      backgroundColor: "gray",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "40px",
                                        fontFamily: "skull",
                                      }}
                                    >
                                      {claimingNft ? "On Process" : "MINT"}
                                    </span>
                                  </button>
                                )}
                              </>
                            )}
                            <div
                              style={{ fontSize: "18px", marginTop: "10px" }}
                            >
                              {mintStatus > 0 ? (
                                <>
                                  {" "}
                                  <span style={{ color: "#fff" }}>
                                    {feedback}
                                  </span>{" "}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <span style={{ color: "#fff" }}>
                                    {feedback}
                                  </span>{" "}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const buttonNYConnect = () => {
    return (
      <>
        <a
          className="lab-btn"
          onClick={(e) => {
            e.preventDefault();
            dispatch(connect());
            getData();
          }}
        >
          <span style={{ fontFamily: "colvetica" }}>Connect Wallet</span>
        </a>
      </>
    );
  };

  const buttonError = () => {
    return (
      <>
        <a
          className="lab-btn-error"
          style={{ fontFamily: "colvetica", fontSize: "20px" }}
        >
          <span>
            {blockchain.errorMsg == "Install Wallet." ? (
              <> Install Wallet </>
            ) : (
              <>
                {blockchain.errorMsg == "Change network to Ethereum." ? (
                  <> Change Network </>
                ) : (
                  <> Switch Network </>
                )}
              </>
            )}
          </span>
        </a>
      </>
    );
  };

  // Control
  useEffect(() => {
    getConfig();
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    if (data.isPresale)
      dispatch(fetchWhitelist(CONFIG.API, blockchain.account));
  }, [data.isPresale]);

  return (
    <>
      {/* Header */}
      <header className="header-section">
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">
                <a href="https://skulourful.com/" target="_blank">
                  <img src="assets/images/logo/logo-topbar.png" />
                </a>
              </div>
              <div className="menu-area">
                <div className="cart-ticket">
                  {blockchain.account == null && blockchain.errorMsg == "" ? (
                    buttonNYConnect()
                  ) : (
                    <>
                      {blockchain.errorMsg != "" ? (
                        buttonError()
                      ) : (
                        <>
                          <a
                            className="lab-btn-reverse"
                            style={{ fontFamily: "colvetica" }}
                          >
                            <span>
                              <i className="fa fa-user"></i>{" "}
                              {blockchain.account.substring(0, 6) +
                                "...." +
                                blockchain.account.substring(
                                  blockchain.account.length - 4
                                )}{" "}
                            </span>
                          </a>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="header-bar d-lg-none">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Banner */}
      <section className="banner-section" style={{ minHeight: "791px" }}>
        <div className="container">
          <div className="banner-wrapper shape-a">
            {blockchain.account == null && blockchain.errorMsg == "" ? (
              panelNYConnect()
            ) : (
              <>
                {blockchain.errorMsg != ""
                  ? panelError()
                  : data.isStartMinting
                  ? panelMint()
                  : panelNotLive()}
              </>
            )}
          </div>
        </div>
      </section>
      {NFTModal()}
    </>
  );
}

export default App;
