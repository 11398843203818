const initialState = {
  loading: false,
  signature: '0x00',
  error: false,
  errorMsg: '',
  isVIP: false,
};

const whitelistReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHECK_WHITELIST_REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: '',
      };
    case 'CHECK_WHITELIST_SUCCESS':
      return {
        ...state,
        loading: false,
        signature: action.payload.signature,
        isVIP: action.payload.isVIP,
        error: false,
        errorMsg: '',
      };
    case 'CHECK_WHITELIST_FAILED':
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default whitelistReducer;
