const initialState = {
  loading: false,
  totalSupply: 0,
  isPresale: false,
  isStartMinting: false,
  cost: 0,
  whitelistSignature: null,
  error: false,
  errorMsg: "",
  userBalance: 0,
  userMintBalance: 0,
  userMintSkulistBalance: 0,
  userMintOGBalance: 0,
  vipMax: 2,
  regulerMax: 2,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        isPresale: action.payload.isPresale,
        isStartMinting: action.payload.isStartMinting,
        userBalance: action.payload.userBalance,
        userMintBalance: action.payload.userMintBalance,
        userMintSkulistBalance: action.payload.userMintSkulistBalance,
        userMintOGBalance: action.payload.userMintOGBalance,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
